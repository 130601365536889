<template>
  <div
    class="d-flex mb-5 align-center"
    style="position: relative; z-index: 10"
  >
    <v-flex
      v-click-outside="() => {menu = false}"
      xs3
      style="position: relative"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            clearable
            label="от даты"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          @input="menu = false; onClearFilterDate();"
        />
      </v-menu>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import customSort from '../../mixins/customSort';

export default {
  name: 'CurrenciesTopbar',

  mixins: [customSort],

  data() {
    return {
      date: null,
    };
  },

  computed: {
    ...mapGetters({
      pagination: 'currencies/pagination',
    }),
  },

  methods: {

    onClearFilterDate() {
      setTimeout(async () => {
        this.$store.dispatch('currencies/loadCurrencies', {
          itemsPerPage: this.pagination.perPage,
          date: this.date,
        });
      }, 500);
    },

  },
};
</script>

<style scoped>
  .datePicker{
    position: absolute;
    margin-top: 1px;
  }
</style>
