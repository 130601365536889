<template>
  <div>
    <topbar />
    <main-table />
  </div>
</template>

<script>
import Topbar from '@/components/currencies/CurrenciesTopbar.vue';
import MainTable from '../components/currencies/CurrenciesMainTable.vue';

export default {
  name: 'Currencies',
  components: { MainTable, Topbar },
  created() {
    this.$store.dispatch('currencies/loadCurrencies');
  },

  beforeDestroy() {
    this.$store.dispatch('currencies/clearSelected');
  },
};
</script>

<style scoped>

</style>
